import BookmarkIcon from '@/assets/Icons/Homepage/bookmark-icon.svg';
import ClassroomIcon from '@/assets/Icons/Homepage/person-board-icon.svg';
import TargetedICon from '@/assets/Icons/Homepage/target-icon.svg';
import LiveImage1 from '@/assets/Images/Thumbnail/live1.png';
import LiveImage2 from '@/assets/Images/Thumbnail/live2.png';
import LiveImage3 from '@/assets/Images/Thumbnail/live3.png';
import LiveImage4 from '@/assets/Images/Thumbnail/live4.png';
import SambhavImage1 from '@/assets/Images/Thumbnail/sambhav1.png';
import SambhavImage2 from '@/assets/Images/Thumbnail/sambhav2.png';
import SambhavImage3 from '@/assets/Images/Thumbnail/sambhav3.png';
import SambhavImage4 from '@/assets/Images/Thumbnail/sambhav4.png';
import SawalImage1 from '@/assets/Images/Thumbnail/sawal1.png';
import SawalImage2 from '@/assets/Images/Thumbnail/sawal2.png';
import SawalImage3 from '@/assets/Images/Thumbnail/sawal3.png';
import SawalImage4 from '@/assets/Images/Thumbnail/sawal4.png';

import { BatchDataType } from '@/types/const';

export const batchData: BatchDataType = {
  liveClassNotes: {
    name: 'Live class notes',
    batch_name: 'Live Class Notes Batch',
    batch_description: 'This batch focuses on providing notes and resources for live classes.',
    pointers: [
      { heading: 'Interactive Notes', description: 'Detailed notes from live sessions.', icon: TargetedICon },
      { heading: 'Accessible Anytime', description: 'Available for review anytime.', icon: ClassroomIcon },
      { heading: 'Expert Guidance', description: 'Created by subject experts.', icon: BookmarkIcon },
    ],
    batch_youtube_url: 'watch?v=PMczSwDygsI&list=PLrip7OgAm7bNktxOTOXyNv-C7XeEZQA5t',
    images: [
      { src: LiveImage1, url: 'link_to_video1' },
      { src: LiveImage2, url: 'link_to_video2' },
      { src: LiveImage3, url: 'link_to_video3' },
      { src: LiveImage4, url: 'link_to_video4' },
    ],
  },
  sawaalBemisal: {
    name: '100 Sawaal Bemisal',
    batch_name: '100 Sawaal Bemisal Batch',
    batch_description: 'A unique batch solving 100 challenging questions.',
    pointers: [
      {
        heading: 'Challenging Questions',
        description: 'Solve 100 carefully selected questions.',
        icon: TargetedICon,
      },
      { heading: 'Detailed Solutions', description: 'In-depth explanations for each question.', icon: ClassroomIcon },
      { heading: 'Boost Confidence', description: 'Enhance your problem-solving skills.', icon: BookmarkIcon },
    ],
    batch_youtube_url: 'watch?v=o62XPaodczI&list=PLrip7OgAm7bPmKp0-zNrZM-c6JvFt-Nhf',
    images: [
      { src: SawalImage1, url: 'link_to_video5' },
      { src: SawalImage2, url: 'link_to_video6' },
      { src: SawalImage3, url: 'link_to_video7' },
      { src: SawalImage4, url: 'link_to_video8' },
    ],
  },
  sambhavBatch: {
    name: 'Sambhav Batch',
    batch_name: 'Sambhav Batch 2024',
    batch_description:
      'The SAMBHAV Batch by Medway is a specialized program designed to provide focused, high-quality preparation for medical exams. Tailored resources, expert guidance, and strategic study plans help students maximize their potential and achieve success.',
    pointers: [
      {
        heading: 'Targeted Curriculum',
        description:
          'A carefully designed curriculum that covers essential topics, focusing on high-yield areas for medical exams to maximize results.',
        icon: TargetedICon,
      },
      {
        heading: 'Expert-Led Guidance',
        description:
          'Led by experienced faculty who provide strategic insights, helping students tackle complex topics with confidence.',
        icon: ClassroomIcon,
      },
      {
        heading: 'Exam Focused',
        description:
          'The SAMBHAV Batch focuses on exam-relevant topics, preparing students thoroughly for medical entrance requirements.',
        icon: BookmarkIcon,
      },
    ],
    batch_youtube_url: 'watch?v=PMczSwDygsI&list=PLrip7OgAm7bNktxOTOXyNv-C7XeEZQA5t',
    images: [
      { src: SambhavImage1, url: 'watch?v=i__0hs7iOwo' },
      { src: SambhavImage2, url: 'watch?v=PMczSwDygsI' },
      { src: SambhavImage3, url: 'watch?v=LuYlUBA9JF8' },
      { src: SambhavImage4, url: 'watch?v=fBtC9siC5mU' },
    ],
  },
};
