import FacebookIcon from '@/assets/Icons/common/facebook.svg';
import InstagramIcon from '@/assets/Icons/common/instagram.svg';
import LinkedInIcon from '@/assets/Icons/common/linkedin.svg';
import BgMiniTestBanner from '@/assets/Images/Banner/bg-mini-test-banner.png';
import BgMiniSubjectTestManagementBanner from '@/assets/Images/Banner/bg-mini-test-management-banner.png';
import BgSubjectTestBanner from '@/assets/Images/Banner/bg-subject-test-banner.png';
import BgSubjectTestManagementBanner from '@/assets/Images/Banner/bg-subject-test-management-banner.png';
import FacultyImage1 from '@/assets/Images/Faculty/ramanand-faculty.png';
import SubjectTestImage from '@/assets/Images/test-1.png';
import MiniTestImage from '@/assets/Images/test-2.png';
import GrandTestImage from '@/assets/Images/test-3.png';

import { FacultyCardProps, TestData } from '@/types/const';

export type SubjectType = {
  id: number;
  name: string;
  questionBanks: number;
};

export const footerData = {
  about: {
    title: 'Medway Online',
    description:
      'Founded by a group of experienced medical educators, Medway has quickly emerged as a trusted name in medical exam preparation.',
    socialLinks: [
      { icon: FacebookIcon, link: '#', name: 'Facebook' },
      { icon: InstagramIcon, link: '#', name: 'Instagram' },
      { icon: LinkedInIcon, link: '#', name: 'LinkedIn' },
    ],
  },
  links: [
    {
      title: 'Company',
      items: [
        { name: 'Terms & Conditions', link: '#' },
        { name: 'Refund Policy', link: '#' },
        { name: 'Privacy Policy', link: '#' },
        { name: 'Contact Us', link: '#' },
      ],
    },
    {
      title: 'Home',
      items: [
        { name: 'Courses', link: '#' },
        { name: 'Batch', link: '#' },
        { name: 'Programs', link: '#' },
        { name: 'Faculty', link: '#' },
      ],
    },
  ],
  newsletter: {
    title: 'Get Update Newsletter',
    description: 'Subscribe to get the latest news from us',
  },
};

export const facultiesData: FacultyCardProps[] = [
  {
    id: 1,
    name: 'Dr. Ramashankar',
    department: 'Ophthalmology',
    experience: '8yrs',
    description:
      'Dr. Ramashankar expertise in ophthalmology and patient care enriches the learning experience, helping students develop a comprehensive understanding of eye health.',
    image: FacultyImage1,
  },
  {
    id: 2,
    name: 'Dr. Priya',
    department: 'Cardiology',
    experience: '10yrs',
    description:
      'Dr. Priya is a renowned cardiologist who combines experience and innovation to deliver exceptional education and care.',
    image: FacultyImage1,
  },
  // Add more faculty data here...
];

export const testData: TestData[] = [
  {
    subjectTest: {
      testName: 'Subject Test',
      title: 'Subject Test',
      pointers: ['200 question set', 'Subject wise test'],
      imageSrc: SubjectTestImage,
      description:
        'Focus on individual subjects with our 200+ question Subject Tests, designed to deepen understanding and target specific areas for improvement.',
      subjects: {
        image: BgSubjectTestBanner,
        list: [
          { id: 1, name: 'Anatomy' },
          { id: 2, name: 'Biochemistry' },
          { id: 3, name: 'Physiology' },
          { id: 4, name: 'Microbiology' },
          { id: 5, name: 'Pathology' },
          { id: 6, name: 'Pharmacology' },
          { id: 7, name: 'Forensic Medicine & Toxicology' },
          { id: 8, name: 'Community Medicine' },
          { id: 9, name: 'Ophthalmology' },
          { id: 10, name: 'ENT' },
          { id: 11, name: 'Medicine' },
          { id: 12, name: 'Surgery' },
          { id: 13, name: 'Obstetrics & Gynaecology' },
          { id: 14, name: 'Anaesthesia' },
          { id: 15, name: 'Paediatrics' },
          { id: 16, name: 'Dermatology' },
          { id: 17, name: 'Orthopaedics' },
          { id: 18, name: 'Radiology' },
        ],
      },
      testManagement: {
        image: BgSubjectTestManagementBanner,
        title: 'Smart Test Management',
        description: 'Effortlessly organize your study with test scheduling and progress tracking.',
        pointers: [
          {
            pointerHeading: 'Track Your Subject Test Progress',
            pointerDescription:
              'Easily track your Subject Tests with clear sections for Pending, Completed, and Upcoming exams to stay organized.',
          },
          {
            pointerHeading: 'Schedule Your Subject Tests',
            pointerDescription:
              'Plan your study sessions by Scheduling Subject Tests at your convenience, ensuring steady progress and organized preparation.',
          },
        ],
      },
    },
    miniTest: {
      testName: 'Mini Test',
      title: 'Mini Test',
      pointers: ['Customizable question selection', 'AI powered test generator'],
      imageSrc: MiniTestImage,
      description: 'Short and focused tests designed for quick revision and practice on specific topics or chapters.',
      subjects: {
        image: BgMiniTestBanner,
        list: [
          { id: 1, name: 'Anatomy' },
          { id: 2, name: 'Biochemistry' },
          { id: 3, name: 'Physiology' },
          { id: 4, name: 'Microbiology' },
          { id: 5, name: 'Pathology' },
        ],
      },
      testManagement: {
        image: BgMiniSubjectTestManagementBanner,
        title: 'Smart Test Management',
        description: 'Effortlessly organize your study with test scheduling and progress tracking.',
        pointers: [
          {
            pointerHeading: 'Track Your Subject Test Progress',
            pointerDescription:
              'Easily track your Subject Tests with clear sections for Pending, Completed, and Upcoming exams to stay organized.',
          },
          {
            pointerHeading: 'Schedule Your Subject Tests',
            pointerDescription:
              'Plan your study sessions by Scheduling Subject Tests at your convenience, ensuring steady progress and organized preparation.',
          },
        ],
      },
    },
    grandTest: {
      testName: 'Grand Test',
      title: 'Grand Test',
      pointers: ['Combination of all topics', 'Monthly test'],
      imageSrc: GrandTestImage,
      description:
        'Comprehensive tests covering multiple subjects to simulate real exam conditions and assess overall preparation.',
      subjects: {
        image: BgMiniTestBanner,
        list: [
          { id: 1, name: 'Anatomy' },
          { id: 2, name: 'Biochemistry' },
          { id: 3, name: 'Physiology' },
          { id: 4, name: 'Microbiology' },
          { id: 5, name: 'Pathology' },
        ],
      },
      testManagement: {
        image: BgMiniSubjectTestManagementBanner,
        title: 'Smart Test Management',
        description: 'Effortlessly organize your study with test scheduling and progress tracking.',
        pointers: [
          {
            pointerHeading: 'Track Your Subject Test Progress',
            pointerDescription:
              'Easily track your Subject Tests with clear sections for Pending, Completed, and Upcoming exams to stay organized.',
          },
          {
            pointerHeading: 'Schedule Your Subject Tests',
            pointerDescription:
              'Plan your study sessions by Scheduling Subject Tests at your convenience, ensuring steady progress and organized preparation.',
          },
        ],
      },
    },
  },
];

export const subjects: SubjectType[] = [
  { id: 1, name: 'Anatomy', questionBanks: 95 },
  { id: 2, name: 'Biochemistry', questionBanks: 45 },
  { id: 3, name: 'Physiology', questionBanks: 78 },
  { id: 4, name: 'Microbiology', questionBanks: 62 },
  { id: 5, name: 'Pathology', questionBanks: 88 },
  { id: 6, name: 'Pharmacology', questionBanks: 50 },
  { id: 7, name: 'Forensic Medicine & Toxicology', questionBanks: 34 },
  { id: 8, name: 'Community Medicine', questionBanks: 67 },
  { id: 9, name: 'Ophthalmology', questionBanks: 29 },
  { id: 10, name: 'ENT', questionBanks: 40 },
  { id: 11, name: 'Medicine', questionBanks: 99 },
  { id: 12, name: 'Surgery', questionBanks: 85 },
  { id: 13, name: 'Obstetrics & Gynaecology', questionBanks: 71 },
  { id: 14, name: 'Anaesthesia', questionBanks: 23 },
  { id: 15, name: 'Paediatrics', questionBanks: 55 },
  { id: 16, name: 'Dermatology', questionBanks: 48 },
  { id: 17, name: 'Orthopaedics', questionBanks: 36 },
  { id: 18, name: 'Radiology', questionBanks: 60 },
];
